import setupurllive from './setup-url-live'
import setupurluat from './setup-url-uat'

let setup = setupurluat
// if (process.env.REACT_APP_BUILD_ENV === "production") setup = setupurllive;

export default {
  project: 'Matahari Email Landing',
  name: 'Matahari Email Landing',
  url: '',
  idle_limit: 1800000, //180000,
  domain_prefix: 'https://subwayrewards.',
  ...setup,
}
