import React from 'react'
import { ThemeProvider } from '@material-ui/styles'
import { createMuiTheme } from '@material-ui/core/styles'

import './style.css'
import './cms.css'
import 'typeface-roboto'

import pink from '@material-ui/core/colors/pink'

// All the following keys are optional.
// We try our best to provide a great default value.
const textColor = '#616161'
const theme = createMuiTheme({
  palette: {
    primary: {
      // light: grey[900],
      // main: grey[900],
      // dark: red[500],
      light: '#303e48',
      main: '#303e48',
      dark: '#fff',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff744a',
      main: '#ff744a',
      dark: '#ff744a',
      contrastText: '#fff',
    },
    error: {
      main: '#ef9a9a',
    },
    text: {
      primary: textColor,
      secondary: textColor,
    },
    // Used by `getContrastText()` to maximize the contrast between the background and
    // the text.
    contrastThreshold: 3,
    // Used to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.1,
  },
  overrides: {
    // MuiInputBase: {
    //   root: {
    //     backgroundColor: "#d5ebd9",
    //     borderStyle: "solid",
    //     borderWidth: 0.5,
    //     borderColor: "grey",
    //     borderRadius: 5
    //   }
    // },
    MuiInput: {
      input: {
        borderRadius: 5,
        borderWidth: 0,
        '&:before': {
          borderBottom: 0,
        },
        '&:hover': {
          borderBottom: 0,
        },
        '&:after': {
          borderBottom: 0,
        },
        '&:focus': {
          borderWidth: 2,
          borderStyle: 'solid',
          borderColor: '#098d42',
          borderRadius: 5,
          marginTop: -1,
          marginBottom: -1,
          marginleft: 2,
          marginRight: -2,
        },
      },
      underline: {
        borderRadius: 5,
        borderStyle: 'solid',
        borderWidth: 0.5,
        borderColor: 'rgba(0, 0, 0, 0.23)',
        '&:before': {
          // borderBottom: 0,
          borderStyle: 'solid',
          borderColor: 'transparent',
        },
        '&:hover': {
          borderStyle: 'solid',
          borderColor: 'rgba(0, 0, 0, 0.23)',
        },
        '&:after': {
          // borderBottom: 0
        },
      },
    },
    MuiButton: {
      root: {
        textTransform: 'none',
        borderColor: '#303e48',
        borderStyle: 'solid',
        borderWidth: 2,
        '&:hover': {
          color: '#303e48',
          backgroundColor: '#fff',
        },
        '& a': {
          textDecoration: 'none',
        },
        '& a:hover': { textDecoration: 'none' },
      },
      contained: {
        boxShadow: 'none',
      },
    },
  },
  typography: {
    h5: {
      color: textColor,
    },
    subtitle2: {
      color: textColor,
    },
  },
})

const Theme = (props) => {
  return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
}

export default Theme
