import pageRegistrationAccountActivate from '../../../app-registration-page-account-activation'

/* subway: start */
const moduleRegistration = [pageRegistrationAccountActivate]
/* subway: end */

const modules = {
  registration: moduleRegistration,
}

export default modules
