import React from 'react'
import { ThemeProvider } from '@material-ui/styles'
import { createMuiTheme } from '@material-ui/core/styles'

import Button from '@material-ui/core/Button'

/*
: properties
children
size
fullWidth
*/
const redTheme = createMuiTheme({
  palette: {
    primary: {
      light: '#fff',
      main: '#fff',
      dark: '#fff',
      contrastText: '#098d42',
    },
  },
  overrides: {
    MuiButton: {
      root: {
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
      },
    },
  },
})

const normalText = (props) => {
  return (
    <ThemeProvider theme={redTheme}>
      <Button {...props} variant="contained" color="primary" type="submit">
        {props.children}
      </Button>
    </ThemeProvider>
  )
}

export default normalText
