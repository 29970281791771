import React from 'react'

/* package */
import {
  SectionWrappSingle,
  CustomThemeWrapper,
} from '../../lib-atomic-section'
/* package */

/* container */
import Container from '../../app-registration-container/default'
/* container */

/* form */
import Content from '../layout/content'
/* form */

const index = (props) => {
  return (
    // <CustomThemeWrapper {...{ ...props, themeTitle: 'MATAHARI' }}>
    <SectionWrappSingle {...props}>
      <Container.AccountActivation {...props}>
        <Content {...props} />
      </Container.AccountActivation>
    </SectionWrappSingle>
    // </CustomThemeWrapper>
  )
}

export default index
