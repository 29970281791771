import appConf from '../app-conf'
const setup = appConf[process.env.REACT_APP_CLIENT]
const environment = process.env.NODE_ENV
const project = 'CMS'
const server = null
const service = null
const config = {
  details: {
    project,
    server,
    environment,
    service,
  },
  application: {
    api: {
      endpoints: {
        accountactivate: {
          headers: {
            'Content-Type': 'application/json',
          },
          url:
            setup.setup.api.matahari.url[environment] +
            '/matahari-api/registration/pos-activation',
          method: 'POST',
        },
      },
    },
  },
  routes: {
    label: 'Note',
    prefix: '/note/container',
  },
}

export default config
