import { init } from './init'
import { sanitizeData, sanitizeExpire } from './sanitize'

const reset = async (state) => {
  state = {
    ...init,
  }
  return state
}

const loading = async (state) => {
  state = {
    ...init,
    isLoading: true,
  }
  return state
}

const set = async (state, data) => {
  console.log(state, data)
  state = {
    ...state,
    isLoading: false,
    isStatus: data.status,
    data: data.data.json,
  }
  return state
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET':
      return set(state, action.data)
    case 'LOAD':
      return loading(state)
    case 'RESET':
      return reset(state)
    default:
      return state
  }
}

const token = {
  init,
  reducer,
}

export default token
