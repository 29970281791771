import React from 'react'
import { ThemeProvider } from '@material-ui/styles'
import { createMuiTheme } from '@material-ui/core/styles'

/*
: properties
children
size
fullWidth
*/
const DEFAULTTHEME = createMuiTheme({
  palette: {
    primary: {
      light: '#ff744a',
      main: '#ff744a',
      dark: '#ff744a',
      contrastText: '#fff',
    },
  },
})
const textColor = '#1d8f3b'
const MATAHARI = createMuiTheme({
  palette: {
    primary: {
      light: '#098d42',
      main: '#098d42',
      dark: '#ffc20d',
      contrastText: '#fff',
    },
    text: {
      primary: textColor,
      secondary: textColor,
    },
  },
  overrides: {
    // MuiInputBase: {
    //   root: {
    //     backgroundColor: "#d5ebd9",
    //     borderStyle: "solid",
    //     borderWidth: 0.5,
    //     borderColor: "grey",
    //     borderRadius: 5
    //   }
    // },
    MuiInput: {
      input: {
        borderRadius: 5,
        borderWidth: 0,
        '&:before': {
          borderBottom: 0,
        },
        '&:hover': {
          borderBottom: 0,
        },
        '&:after': {
          borderBottom: 0,
        },
        '&:focus': {
          borderWidth: 2,
          borderStyle: 'solid',
          borderColor: '#098d42',
          borderRadius: 5,
          marginTop: -1,
          marginBottom: -1,
          marginleft: 2,
          marginRight: -2,
        },
      },
      underline: {
        borderRadius: 5,
        borderStyle: 'solid',
        borderWidth: 0.5,
        borderColor: 'rgba(0, 0, 0, 0.23)',
        '&:before': {
          // borderBottom: 0,
          borderStyle: 'solid',
          borderColor: 'transparent',
        },
        '&:hover': {
          borderStyle: 'solid',
          borderColor: 'rgba(0, 0, 0, 0.23)',
        },
        '&:after': {
          // borderBottom: 0
        },
      },
    },
    MuiButton: {
      root: {
        textTransform: 'none',
        '&:hover': {
          color: '#098d42',
        },
        '& a': {
          textDecoration: 'none',
        },
        '& a:hover': { textDecoration: 'none' },
      },
      contained: {
        boxShadow: 'none',
      },
    },
  },
  typography: {
    h5: {
      color: textColor,
    },
    h6: {
      color: textColor,
    },
    subtitle2: {
      color: textColor,
    },
  },
})
const themeArr = [{ title: 'MATAHARI', theme: MATAHARI }]

const themeBlock = (props) => {
  const { themeTitle } = props
  const themeFound = themeArr.filter((themeItem) => {
    return themeItem.title === themeTitle
  })
  const themeSelected =
    themeFound.length > 0 ? themeFound[0].theme : DEFAULTTHEME

  return <ThemeProvider theme={themeSelected}>{props.children}</ThemeProvider>
}

export default themeBlock
