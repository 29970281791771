import React from 'react'

import AccountActivationContainer from '../sub-container/registration-account-activation-container'

const AccountActivation = (props) => {
  return (
    <AccountActivationContainer {...props}>
      {props.children}
    </AccountActivationContainer>
  )
}

export default { AccountActivation }
