import React from 'react'

import { makeStyles } from '@material-ui/core/styles'

import Header from './header'
import Body from './body'

import appConf from '../../components/app-conf'

import { GlobalProvider } from '../../components/lib-atomic-context'

const Themes = appConf[process.env.REACT_APP_CLIENT].themes

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    paddingTop: theme.spacing(10),
    paddingLeft: theme.spacing(0),
    paddingBottom: theme.spacing(10),
  },
}))

const Main = (props) => {
  const classes = useStyles()

  return (
    <Themes>
      <GlobalProvider>
        <Header {...props} />
        <div className={classes.root}>
          <main className={classes.content}>
            <Body {...props} />
          </main>
        </div>
      </GlobalProvider>
    </Themes>
  )
}

export default Main
