import React from 'react'
import moment from 'moment'

/* atoms */
import { makeStyles } from '@material-ui/core/styles'
import { Invalid } from '../feedbacks'

/* atoms */

/*
: properties
name,
label,
value,
onChange,
helperText,
error
*/

import 'date-fns'
import DateFnsUtils from '@date-io/date-fns'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  // DatePicker,
} from '@material-ui/pickers'

const useStyles = makeStyles((theme) => ({
  root: {
    height: 40,
    paddingLeft: 14,
    border: '1px solid #c4c4c4',
    borderRadius: 4,
    // '& > .MuiPickerDTToolbar-toolbar.MuiToolbar-root': {
    //   backgroundColor: 'green',
    // },
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:hover': {
      backgroundColor: '#fff',
      borderColor: '#757575',
    },
    '&$focused': {
      backgroundColor: '#fff',
      borderColor: '#385be8',
    },
    color: 'red',
  },
  checked: {},
}))

const Date = (props) => {
  const classes = useStyles()
  const { formatDisplay, formatProcess, initial, name, label, onPick } = props

  const [selectedDate, setSelectedDate] = React.useState(initial)
  const [isFocused, setIsFocused] = React.useState(false)

  const handleDateChange = (date) => {
    setSelectedDate(null)
    if (!date) {
      date = ''
    } else if (date === null) {
      setSelectedDate(null)
    } else {
      setSelectedDate(date)
      date = moment(date).format('LL')
    }
    onPick(name, date)
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      {/* disableToolbar */}
      <KeyboardDatePicker
        key="DatePicker"
        name={name}
        error={false}
        helperText={null}
        label={label}
        autoOk
        fullWidth
        inputVariant="outlined"
        format={formatDisplay}
        margin="dense"
        id="standard-full-width"
        value={selectedDate}
        onChange={handleDateChange}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
        InputProps={{
          disableUnderline: true,
        }}
        onFocus={() => {
          setIsFocused(true)
        }}
        onBlur={() => {
          setIsFocused(false)
        }}
        InputLabelProps={{ shrink: selectedDate || isFocused ? true : false }}
      />
      <Invalid {...props} />
    </MuiPickersUtilsProvider>
  )
}

export default Date
