import React, { Fragment, useContext, useState } from 'react'

import {
  AppBar,
  Toolbar,
  Grid,
  IconButton,
  makeStyles,
  Container,
} from '@material-ui/core'
import { LocalStorage } from '../../components/lib-atomic-functions'
import appConf from '../../components/app-conf'

import Link from '../../components/lib-atomic/link'

const header = appConf[process.env.REACT_APP_CLIENT].assets.header

const useStyles = makeStyles((theme) => ({
  appBar: {
    boxShadow: 'none',
    width: '100%',
    backgroundColor: 'white',
  },
}))
const Header = (props) => {
  const classes = useStyles()
  const [isVisible, setIsVisible] = useState(false)
  const token = LocalStorage.getLS()
    ? LocalStorage.getLS().login
      ? LocalStorage.getLS().login.token
        ? LocalStorage.getLS().login.token
        : false
      : false
    : false
  const drawerProps = { ...props, isVisible, setIsVisible }
  return (
    <Fragment>
      <AppBar position="sticky" className={classes.appBar}>
        <Container maxWidth={'md'}>
          <Grid
            // stretch
            style={{
              width: '100%',
              justifyContent: 'center',
              margin: 'auto',
              textAlign: 'center',
            }}
          >
            <img
              className="subway-footlong"
              style={{ width: '100%' }}
              src={header}
            />
          </Grid>
        </Container>
      </AppBar>
    </Fragment>
  )
}

export default Header
