import React, { createContext, useReducer, useState } from 'react'

import monoReducer from './reducer'
import UseAsyncReducer from './useAsyncReducer'

export const GlobalContext = createContext()

export const GlobalProvider = ({ children }) => {
  const modules = ['accountactivate']

  let reducerObj = {}
  modules.map((module) => {
    reducerObj[module] = {
      ...monoReducer,
      method: UseAsyncReducer(monoReducer.reducer, {
        ...monoReducer.init,
      }),
      reducerName: module,
    }
  })

  const stores = {}
  Object.keys(reducerObj).map((reducer) => {
    stores[reducer] = {
      state: reducerObj[reducer].method[0],
      dispatch: reducerObj[reducer].method[1],
    }
  })

  return (
    <GlobalContext.Provider value={stores}>{children}</GlobalContext.Provider>
  )
}
