import React from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import Fade from '@material-ui/core/Fade'
import CircularProgress from '@material-ui/core/CircularProgress'
import { green } from '@material-ui/core/colors'
import Fab from '@material-ui/core/Fab'
import SaveIcon from '@material-ui/icons/Save'
import Dialog from '@material-ui/core/Dialog'
import HeaderPlaceholder from '../placeholderHeader/headerPlaceholder'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    width: '0%',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
    margin: 'auto',
    justifyContent: 'center',
  },
  buttonSuccess: {
    backgroundColor: 'white',
    margin: 'auto',
    margin: 15,
    justifyContent: 'center',
    '&:hover': {
      backgroundColor: 'white',
    },
  },
  fabProgress: {
    color: '#aa2239',
    position: 'absolute',
    top: -6.5,
    left: -6.5,
    zIndex: 1,
  },
  buttonProgress: {
    color: 'white',
    margin: 'auto',
    justifyContent: 'center',
  },
  root2: {
    overflow: 'unset',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 'auto',
    width: '55%',
    minWidth: 300,
    maxWidth: 500,
  },
  throbber: {
    color: '#ffffff',
  },
}))
const Header = () => {
  return <HeaderPlaceholder />
}
const Loader = ({ color, size, loading }) => {
  const classes = useStyles()
  return (
    <div
      style={{
        justifyContent: 'center',
        margin: 'auto',
      }}
    >
      {/* modal */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={loading}
        closeAfterTransition
      >
        <Fade in={loading}>
          <CircularProgress
            className={classes.throbber}
            size={30}
            thickness={5}
          />
        </Fade>
      </Modal>
    </div>
  )
}

const Popup = ({ selectedValue }) => {
  const classes = useStyles()
  const [loading, setLoading] = React.useState(true)
  const [success, setSuccess] = React.useState(false)
  const [isMac, setIsMac] = React.useState(false)
  const timer = React.useRef()

  const buttonClassname = clsx({
    [classes.buttonSuccess]: success,
  })

  const checkDevice = () => {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod',
      'macintel',
    ].some(function (exactPlatformString) {
      return window.navigator.platform === exactPlatformString
    })
  }

  React.useEffect(() => {
    const isMac1 = checkDevice() ? true : false
    setIsMac(isMac1)
    return () => {
      clearTimeout(timer.current)
    }
  }, [])

  const handleButtonClick = () => {
    if (!loading) {
      setSuccess(false)
      setLoading(true)
      timer.current = setTimeout(() => {
        setSuccess(true)
        setLoading(false)
      }, 2000)
    }
  }

  return (
    <div>
      <Dialog
        maxWidth="sm"
        className={classes.root2}
        aria-labelledby="select-country"
        open={true}
        PaperProps={{
          style: {
            overflow: 'unset',
            backgroundColor: 'transparent',
            boxShadow: 'none',
            justifyContent: 'center',
          },
        }}
      >
        <div className={classes.root}>
          <div className={classes.wrapper}>
            <Fab
              aria-label="save"
              style={{ backgroundColor: 'white' }}
              className={buttonClassname}
              onClick={handleButtonClick}
            >
              {success ? (
                <SaveIcon />
              ) : (
                <img
                  src="/assets/matahari/logo.png"
                  alt="logo"
                  width="31"
                  style={{
                    margin: 'auto',
                    padding: 15,
                    // marginTop: isMac ? -7.5 : 0,
                    justifyContent: 'center',
                  }}
                />
              )}
            </Fab>
            {loading && (
              <CircularProgress size={68} className={classes.fabProgress} />
            )}
          </div>
        </div>
      </Dialog>
    </div>
  )
}
export default { Header, Popup, Loader }
