export default {
  thor: {
    moduleCode: {
      production: '',
      development: '',
    },
    programId: null,
  },
  api: {
    tranxactor: {
      url: {
        // UAT TX Server
        production: 'https://subcarduat.tr4ns.com/tx-auth',
        development: 'https://subcarduat.tr4ns.com/tx-auth',
        // live TX Server
        // production: 'https://rewards.subway.co.uk/tx-auth',
        // development: 'https://rewards.subway.co.uk/tx-auth',
      },
    },
    matahari: {
      url: {
        // UAT TX Server
        production: 'https://matahariapiuat.tr4ns.com',
        development: 'https://matahariapiuat.tr4ns.com',
      },
    },
  },
}
