import React, { useEffect, useContext } from 'react'
import QueryString from 'query-string'

/* packages */
import { GlobalContext } from '../../lib-atomic-context'
import {
  FetchContextApi,
  FetchContextApiV2,
  LocalStorage,
} from '../../lib-atomic-functions'
/* packages */

/* module */
import Spinner from '../../app-module-section-spinner/default'
/* module */

import CONFIG from '../config'
/* CONSTANTS */

const ENDPOINTS = CONFIG.application.api.endpoints
const ACCOUNTACTIVATECONFIG = {
  url: ENDPOINTS.accountactivate.url,
  method: ENDPOINTS.accountactivate.method,
  headers: ENDPOINTS.accountactivate.headers,
  responseType: 'json',
}

const Index = (props) => {
  const { activationCode } = props.match.params
  const { accountactivate } = useContext(GlobalContext)

  /* --- reset */
  const fetchAccountActivateExec = async ({ query, body }) => {
    // ACCOUNTACTIVATECONFIG.headers.Authorization = 'Bearer ' + token
    const [fetchAccountActivate] = FetchContextApiV2(ACCOUNTACTIVATECONFIG)
    accountactivate.dispatch({ type: 'LOAD' })
    const fetchResult = await fetchAccountActivate(
      { query, body },
      accountactivate
    )
    accountactivate.dispatch({
      type: 'SET',
      data: fetchResult,
    })
  }
  useEffect(() => {
    const body = {
      activationCode: activationCode,
    }
    fetchAccountActivateExec({ body })
  }, [])

  const isActivationReady =
    accountactivate.state.data && !accountactivate.state.isLoading
      ? true
      : false
  if (!isActivationReady) {
    return <Spinner.Popup />
  }

  return props.children
}

export default Index
