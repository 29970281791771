import React, { useContext, Fragment, useState, useEffect } from 'react'
import marked from 'marked'

/* package */
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { Buttons, Inputs } from '../../lib-atomic'
import { WindowSize, FetchContextApiV2 } from '../../lib-atomic-functions'
import { GlobalContext } from '../../lib-atomic-context'
import { useSnackbar } from 'notistack'
/* package */

/* modules */
import CONFIG from '../config'

import Spinner from '../../app-module-section-spinner/default'
// import Popup from '../../app-popup-section-confirm/default'
import Popup from '../popup/index'
/* CONSTANTS */

const ENDPOINTS = CONFIG.application.api.endpoints
const ACCOUNTACTIVATECONFIG = {
  url: ENDPOINTS.accountactivate.url,
  method: ENDPOINTS.accountactivate.method,
  headers: ENDPOINTS.accountactivate.headers,
  responseType: 'json',
}
/* CONSTANTS */

const useStyles = makeStyles((theme) => ({
  root: {
    '&:hover': {
      cursor: 'pointer',
    },
    height: 140,
  },
  upper: {
    textTransform: 'uppercase',
  },
  camel: {
    textTransform: 'capitalize',
  },
}))

const Index = (props) => {
  const { width, height } = WindowSize()
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const [popupMessage, setPopupMessage] = useState(false)
  const [popupMessage2, setPopupMessage2] = useState(false)
  const [visiblePopup, setVisiblePopup] = useState(false)
  const { accountactivate } = useContext(GlobalContext)

  /* --- reset */
  // const fetchResetExec = async () => {
  //   await accountactivate.dispatch({
  //     type: 'RESET',
  //   })
  //   setLoaded(true)
  // }
  /* --- reset */

  useEffect(() => {
    if (accountactivate.state.data) {
      console.log('ay2 ', accountactivate)
      if (!accountactivate.state.isStatus) {
        setPopupMessage('Activation Unsuccessful :(')
        setPopupMessage2(
          accountactivate.state.data.response.data.outcomeMessage
        )
        setLoaded(true)
        // fetchResetExec()
        return
      }
      console.log('2. account activation call responded..')
      const resObj = accountactivate.state.data

      console.log(resObj)
      setPopupMessage('Activation Successful :)')
      setPopupMessage2('Your account has been activated')
      setLoaded(true)
      return
    }
  }, [])

  return (
    <Fragment>
      <>
        <div>
          <Typography variant="h5">
            <b>{popupMessage}</b>
          </Typography>
          <Typography variant="body2" style={{ paddingLeft: 10 }}>
            <br />
            {popupMessage2}
          </Typography>
        </div>
        <br />
      </>
    </Fragment>
  )
}

export default Index
