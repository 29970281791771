const defaultRole = 0
const manager = (LocalStorage) => {
  // roles = 0 = guest user
  // roles = 1 = creator
  // roles = 2 = approver
  // roles = 3 = publisher
  // roles = 4 = super user

  try {
    // check if token exist in login LS
    // const { strapi } = LocalStorage.getLS()
    // console.log(strapi)
    // if (!strapi) throw 'no login'
    // if (strapi.role === 'creator') return 1
    // if (strapi.role === 'approver') return 2
    // if (strapi.role === 'publisher') return 3
    // if (strapi.role === 'creator+approver+publisher') return 4
    return 0
  } catch (e) {
    return defaultRole
  }
}

export default manager
