import React from 'react'

/* mui */
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
/* mui */

/* CONSTANTS */
const useStyles = makeStyles((theme) => ({
  text: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
    color: '#222222',
  },
}))
/* CONSTANTS */

const Index = (props) => {
  const { variant, fontWeight } = props
  /* mui */
  const classes = useStyles()
  /* mui */

  return (
    <Typography
      variant={variant ? variant : 'body2'}
      className={classes.text}
      style={{ fontWeight: fontWeight ? fontWeight : null }}
    >
      {props.children}
    </Typography>
  )
}

export default Index
