/* packages */
import FetchBlob from '../fetch-blob'
import AxiosJson from '../axios-json'
/* packages */

const index = ({ url, method, headers, responseType }) => {
  const funcMethod = async ({ body, query, newHeader }, store) => {
    store.state = { ...store.state, isLoading: true }
    var new_url = JSON.parse(JSON.stringify(url))
    if (newHeader) headers = newHeader
    if (query) new_url = new_url + query
    let fetch = null
    if (responseType === 'blob') {
      // blob
      fetch = await FetchBlob({
        url: new_url,
        config: {
          method,
          headers,
          body,
        },
      })
    } else {
      // json
      console.log({
        method,
        headers,
        data: body,
      })
      fetch = await AxiosJson({
        url: new_url,
        config: {
          method,
          headers,
          data: body,
        },
      })
    }
    console.log(fetch)
    store.state = { ...store.state, isLoading: false }
    return { status: fetch[0], data: fetch[1] }
  }
  return [funcMethod]
}

export default index
